import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo";
import Img from 'gatsby-image'
import { Button } from "@material-ui/core";
import ReactangleArea from '../components/common/RectangleArea'
import AppLink from "../components/common/link/appLink";


const IndexPage = (props) => {

  console.log("Hello...")

  console.log("data", props.data)

  return (
    <Layout>
      <SEO title="Admin MyZone" />
      <div className="pt-28 ">
        <div className="flex   flex-col items-center min-h-screen">

          <div className="prose md:prose-xl max-w-none text-center mb-20">

            <h1 className="text-purple-600 bg-purple-500 inline-flex px-5 py-2" style={{ color: 'white' }}>Welcome! to MyZone.</h1>
            <p>MyZone is specifically targeted for Admins of Ashramblr.org. Get the best content and Tips for writing.</p>
            <p>Now go build something great.</p>


            <div>
              <div className="flex justify-center items-center gap-5">
                <a href="https://ashramblr.org" target="_blank" rel="noopener noreferrer" className="px-5 py-5 block border-purple-500 border-4 rounded-md bg-purple-500 text-white uppercase   font-bold">
                  Visit ashramblr
                </a>
                <AppLink to="/titleToUrl">
                  <ReactangleArea>
                    Convert your title to url
                  </ReactangleArea>
                </AppLink>
              </div>

            </div>



          </div>
          <div className="grid grid-cols-12 mb-36">
            <div className="col-start-2 col-end-12 ">
              <div className="prose-xl prose max-w-none">
                <h2>Write Better, Rank Better</h2>
                <ul>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.hubspot.com/marketing/types-of-blog-headlines">
                      types-of-blog-headlines
                    </a>
                  </li>
                  <li>
                    <a href="http://" target="_blank" rel="noopener noreferrer"></a>
                  </li>

                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.wordstream.com/blog/ws/2014/07/17/headline-writing">
                      19 Headline Writing Tips for More Clickable, Shareable Blog Posts
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://bloggingwizard.com/write-better-blog-headlines/">
                      How To Write Better Blog Headlines
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.blogtyrant.com/how-to-write-the-perfect-blog-post/">
                      HOW TO WRITE A GOOD BLOG POST: 12 EXPERT TIPS
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://smartblogger.com/how-to-write-a-blog-post/">
                      How to Write a Blog Post in 2021: The Ultimate Guide
                    </a>
                  </li>

                </ul>

                <h2>Internal Resources</h2>
                <ul className="">

                  {
                    props.data.allMdx.nodes.map((node, i) => {
                      return (
                        <li key={i}>
                          <Link to={node.fields.slug}>
                            {node.frontmatter.title}
                          </Link>
                        </li>
                      )
                    })
                  }

                  {/* <li>
                    <Link to="/howToEmbedYoutubeAndOtherLinks/">
                      How To directly use or embed Youtube videos in your News / Sewa Activiy/Article etc..
                  </Link>
                  </li>
                  <li>
                    <Link to="/BetterImages/">
                      Better Image to Rank High
                  </Link>
                  </li>
                  <li>
                    <a href="https://www.labnol.org/embed/google/photos/" target="_blank" rel="noopener noreferrer">
                      Labnol, Google Photos to direct Image link
          </a>
                  </li> */}

                </ul>
              </div>

            </div>
          </div>


        </div>
      </div>

    </Layout >
  )
}

export default IndexPage



export const data = graphql`
{
  allMdx(filter:{frontmatter:{collection_type:{eq:"blog"}}}){
    nodes{
      fields{
        slug
      }
      frontmatter{
        title
      }
    }
  }
}

`
