import { Link } from 'gatsby'
import React, { Component } from 'react'

export default class AppLink extends Component {
    render() {
        let styles = {
            textDecoration: 'none'
        }
        return (
            <Link to={this.props.to} className={` ${this.props.to ? 'hover:text-secondary' : ''}  transition duration-300 no-underline ${this.props.className}`} style={Object.assign({},styles, this.props.style)} {...this.props}>
                {
                    this.props.children
                }
            </Link>
        )
    }
}
